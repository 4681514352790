import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n"; // Path to your i18n.js
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import About from "../components/About";
import Features from "../components/Features";
import AppDownload from "../components/AppDownload";

const Home = () => (
    <I18nextProvider i18n={i18n}>
        <Header />
        <Hero />
        <About />
        <Features />
        <AppDownload />
        <Footer />
    </I18nextProvider>
);

export default Home;
