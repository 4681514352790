import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TermsAndConditions from "../components/TermsAndConditions";


const TermsAndConditionsPage = () => (
    <>
        <Header />
        <TermsAndConditions />
        <Footer />
    </>
);

export default TermsAndConditionsPage;
