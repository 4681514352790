import React from "react";
import { Typography, TextField, Button, Box } from "@mui/material";

const Contact = () => (
    <Box
        id="contact"
        sx={{
            padding: "50px 20px",
            paddingTop: "114px", // Offset for the fixed header (64px header + 50px section padding)
            backgroundColor: "var(--color-white)", // White background for the section
            textAlign: "center",
        }}
    >
        {/* Header */}
        <Typography
            variant="h4"
            component="h1"
            sx={{
                fontWeight: "bold",
                color: "var(--color-blue)", // Blue header for emphasis
                marginBottom: "20px",
            }}
        >
            Contact Us
        </Typography>

        {/* Subtitle */}
        <Typography
            sx={{
                fontSize: "1.2rem",
                color: "var(--color-black)", // Black text for readability
                marginBottom: "30px",
            }}
        >
            Have questions? We're here to help! Fill out the form below or reach us at{" "}
            <a
                href="mailto:support@swiftcom.com"
                style={{ color: "var(--color-blue)", textDecoration: "none" }}
            >
                support@swiftcom.com
            </a>
            .
        </Typography>

        {/* Form */}
        <Box
            component="form"
            sx={{
                margin: "0 auto",
                maxWidth: "600px", // Limit form width for better layout
                textAlign: "left",
            }}
        >
            <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "var(--color-blue)", // Blue border for inputs
                        },
                        "&:hover fieldset": {
                            borderColor: "var(--color-black)", // Black border on hover
                        },
                    },
                }}
            />
            <TextField
                fullWidth
                label="Email"
                variant="outlined"
                margin="normal"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "var(--color-blue)",
                        },
                        "&:hover fieldset": {
                            borderColor: "var(--color-black)",
                        },
                    },
                }}
            />
            <TextField
                fullWidth
                label="Message"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "var(--color-blue)",
                        },
                        "&:hover fieldset": {
                            borderColor: "var(--color-black)",
                        },
                    },
                }}
            />
            <Button
                type="submit"
                variant="contained"
                sx={{
                    marginTop: "20px",
                    backgroundColor: "var(--color-blue)", // Blue button
                    color: "var(--color-white)", // White text
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    ":hover": {
                        backgroundColor: "var(--color-black)", // Black background on hover
                    },
                }}
            >
                Submit
            </Button>
        </Box>
    </Box>
);

export default Contact;
