import React from "react";
import ReactDOM from "react-dom";
import App from "./App"; // Ensure the correct path
import '@my-monorepo/shared-ui/styles/swiftcom_styles.css';


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root") // Ensure this matches the "root" div in index.html
);
