import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PricingPage from "./pages/PricingPage";
import ContactPage from "./pages/ContactPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Pricing" element={<PricingPage />} />
            <Route path="/Contact" element={<ContactPage />} />
            <Route path="/Terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="/Privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
    </Router>
);

export default App;
