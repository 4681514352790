import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import WorldMap from "../images/anm-world-map.png"; // Import the image

const Hero = () => {
    const { t } = useTranslation();

    return (
        <Box
            id="hero"
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                overflow: "hidden",
            }}
        >
            <Box
                component="img"
                src={WorldMap}
                alt="World Map"
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: -1,
                }}
            />
            <Box sx={{ textAlign: "center", zIndex: 2 }}>
                <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                        color: "var(--color-white)",
                        fontWeight: "bold",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
                        fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
                    }}
                >
                    {t("Welcome to Swiftcom")}
                </Typography>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        color: "var(--color-white)",
                        fontWeight: 300,
                        textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
                        fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
                    }}
                >
                    {t("Seamless Global Communication Solutions")}
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        mt: 4,
                        px: 5,
                        py: 1.8,
                        backgroundColor: "var(--color-blue)",
                        color: "var(--color-white)",
                        fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.6rem" },
                        textTransform: "uppercase",
                        borderRadius: 3,
                        fontWeight: "bold",
                        ":hover": {
                            backgroundColor: "var(--color-black)",
                            color: "var(--color-white)",
                        },
                    }}
                >
                    {t("Learn More")}
                </Button>
            </Box>
        </Box>
    );
};

export default Hero;
