import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrivacyPolicy from "../components/PrivacyPolicy";


const PrivacyPolicyPage = () => (
    <>
        <Header />
        <PrivacyPolicy />
        <Footer />
    </>
);

export default PrivacyPolicyPage;
