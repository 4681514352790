import React from "react";
import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Box
            sx={{
                textAlign: "center",
                mt: { xs: 8, sm: 10 }, // Margin to adjust below header
                px: 2, // Padding for responsive alignment
            }}
        >
            <Typography variant="h5" gutterBottom className="text-black">
                Terms and Conditions
            </Typography>

            <iframe
                src="/privacy-policy.pdf"
                title="Privacy Policy"
                width="100%"
                height="800px"
                style={{
                    border: "none",
                    marginTop: "8px", // Space between title and iframe
                }}
            />
        </Box>
    );
};

export default PrivacyPolicy;
