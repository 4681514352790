import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../components/Contact";


const ContactPage = () => (
    <>
        <Header />
        <Contact />
        <Footer />
    </>
);

export default ContactPage;
