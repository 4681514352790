import React from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";


const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box
            component="footer"
            sx={{
                py: 4,
                px: 2,
                textAlign: "center",
                backgroundColor: "var(--color-blue)",
                color: "var(--color-white)",
            }}
        >
            {/* Social Media Links */}
            {/* <Box sx={{ mb: 3 }}>
                <IconButton
                    href="https://facebook.com"
                    target="_blank"
                    sx={{ color: "var(--color-white)", mx: 1 }}
                >
                    <FacebookIcon />
                </IconButton>
                <IconButton
                    href="https://twitter.com"
                    target="_blank"
                    sx={{ color: "var(--color-white)", mx: 1 }}
                >
                    <TwitterIcon />
                </IconButton>
                <IconButton
                    href="https://instagram.com"
                    target="_blank"
                    sx={{ color: "var(--color-white)", mx: 1 }}
                >
                    <InstagramIcon />
                </IconButton>
                <IconButton
                    href="https://linkedin.com"
                    target="_blank"
                    sx={{ color: "var(--color-white)", mx: 1 }}
                >
                    <LinkedInIcon />
                </IconButton>
            </Box> */}

            {/* Navigation Links */}
            <Typography variant="body2" sx={{ mb: 3 }}>
                <a href="/Privacy-policy" style={{ color: "inherit", textDecoration: "none", marginRight: "15px" }}>
                    {t("Privacy Policy")}
                </a>
                <a href="/Terms-and-conditions" style={{ color: "inherit", textDecoration: "none", marginRight: "15px" }}>
                    {t("Terms of Service")}
                </a>
            </Typography>

            {/* Copyright */}
            <Typography variant="body2" sx={{ mb: 2 }}>
                © 2024 Swiftcom. All Rights Reserved.
            </Typography>

        </Box>
    );
};

export default Footer;
