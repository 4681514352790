import React from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import WorldMap from "../images/World-Map.png";
import { useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();

    return (
        <Box
            id="about"
            sx={{
                position: "relative",
                overflow: "hidden",
                padding: "50px 20px",
                textAlign: "center",
            }}
        >
            {/* Background Image */}
            <Box
                component="img"
                src={WorldMap}
                alt={t("World Map")}
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    opacity: 0.3,
                    zIndex: -1,
                }}
            />

            {/* Main Content */}
            <Box sx={{ zIndex: 2, color: "var(--color-black)" }}>
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                        fontWeight: "bold",
                        color: "var(--color-blue)",
                        marginBottom: "20px",
                    }}
                >
                    {t("About Swiftcom")}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "1.2rem",
                        lineHeight: 1.8,
                        marginBottom: "20px",
                        color: "var(--color-black)",
                    }}
                >
                    {t(
                        "Swiftcom is your trusted partner for seamless global communication. We specialize in providing innovative and cost-effective solutions tailored to meet the needs of travelers and home users alike. Whether you're staying connected with family and friends or managing international business, Swiftcom has got you covered."
                    )}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "1.2rem",
                        lineHeight: 1.8,
                        marginBottom: "40px",
                        color: "var(--color-black)",
                    }}
                >
                    {t(
                        "Our mission is to break down communication barriers by offering reliable, affordable, and globally accessible services. With a focus on flexibility, quality, and ease of use, Swiftcom ensures that you stay connected wherever you are in the world."
                    )}
                </Typography>

                {/* Stats Section */}
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: "var(--color-blue)",
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            {t("200+")}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                color: "var(--color-black)",
                            }}
                        >
                            {t("Countries Covered")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: "var(--color-blue)",
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            {t("10,000+")}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                color: "var(--color-black)",
                            }}
                        >
                            {t("Satisfied Customers")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: "var(--color-blue)",
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            {t("99.9%")}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                color: "var(--color-black)",
                            }}
                        >
                            {t("Uptime Reliability")}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Call-to-Action */}
                <Box sx={{ marginTop: "40px" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "var(--color-blue)",
                            color: "var(--color-white)",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            padding: "10px 20px",
                            fontSize: "1rem",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: "var(--color-black)",
                            },
                        }}
                    >
                        {t("Learn More About Our Services")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default About;
