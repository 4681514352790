import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: {
            "Welcome to Swiftcom": "Welcome to Swiftcom",
            "Seamless Global Communication Solutions": "Seamless Global Communication Solutions",
            "Home": "Home",
            "Download App": "Download App",
            "Prices": "Prices",
            "Contact Us": "Contact Us",
            "Language": "Language 🇬🇧",
            "Login": "Login",
            "Learn More": "Learn More",
            "Submit": "Submit",
            "Have questions? We're here to help! Fill out the form below or reach us at":
                "Have questions? We're here to help! Fill out the form below or reach us at",
            "About Swiftcom": "About Swiftcom",
            "Swiftcom is your trusted partner for seamless global communication. We specialize in providing innovative and cost-effective solutions tailored to meet the needs of travelers and home users alike. Whether you're staying connected with family and friends or managing international business, Swiftcom has got you covered.": "Swiftcom is your trusted partner for seamless global communication. We specialize in providing innovative and cost-effective solutions tailored to meet the needs of travelers and home users alike. Whether you're staying connected with family and friends or managing international business, Swiftcom has got you covered.",
            "Our mission is to break down communication barriers by offering reliable, affordable, and globally accessible services. With a focus on flexibility, quality, and ease of use, Swiftcom ensures that you stay connected wherever you are in the world.": "Our mission is to break down communication barriers by offering reliable, affordable, and globally accessible services. With a focus on flexibility, quality, and ease of use, Swiftcom ensures that you stay connected wherever you are in the world.",
            "Countries Covered": "Countries Covered",
            "Satisfied Customers": "Satisfied Customers",
            "Uptime Reliability": "Uptime Reliability",
            "Learn More About Our Services": "Learn More About Our Services",
            "Why Choose Swiftcom?": "Why Choose Swiftcom?",
            "Global Data eSIMs": "Global Data eSIMs",
            "Stay connected worldwide with affordable data plans that work seamlessly across multiple networks in each country.": "Stay connected worldwide with affordable data plans that work seamlessly across multiple networks in each country.",
            "Local Numbers (DIDs)": "Local Numbers (DIDs)",
            "Receive calls and SMS at local rates with dedicated numbers in over 60 countries.": "Receive calls and SMS at local rates with dedicated numbers in over 60 countries.",
            "Global Outgoing Calls & SMS": "Global Outgoing Calls & SMS",
            "Make international calls and send SMS at competitive rates, avoiding high roaming charges.": "Make international calls and send SMS at competitive rates, avoiding high roaming charges.",
            "Call Forwarding": "Call Forwarding",
            "Forward calls from your native SIM to Swiftcom and never miss an important call while traveling.": "Forward calls from your native SIM to Swiftcom and never miss an important call while traveling.",
            "Explore Our Plans": "Explore Our Plans",
            "Calculate Your Costs": "Calculate Your Costs",
            "Select a DID Country": "Select a DID Country",
            "DID Cost": "DID Cost",
            "Call Minutes": "Call Minutes",
            "Call Destination Country": "Call Destination Country",
            "Call Cost": "Call Cost",
            "SMS Count": "SMS Count",
            "SMS Destination Country": "SMS Destination Country",
            "SMS Cost": "SMS Cost",
            "Data in": "Data in",
            "Cost per GB in": "Cost per GB in",
            "Subscription Fee": "Subscription Fee",
            "Free (First Month)": "Free (First Month)",
            "Calculate Total": "Calculate Total",
            "Total Cost": "Total Cost",
            "Loading...": "Loading...",
            "Get the Swiftcom App": "Get the Swiftcom App",
            "Stay connected globally with our mobile app, available on both iOS and Android.":
                "Stay connected globally with our mobile app, available on both iOS and Android.",    
   
        },
    },
    es: {
        translation: {
            "Welcome to Swiftcom": "Bienvenido a Swiftcom",
            "Seamless Global Communication Solutions": "Soluciones de comunicación global sin interrupciones",
            "Home": "Inicio",
            "Download App": "Descargar aplicación",
            "Prices": "Precios",
            "Contact Us": "Contáctenos",
            "Language": "Idioma 🇪🇸",
            "Login": "Iniciar sesión",
            "Learn More": "Aprender más",
            "Submit": "Enviar",
            "Have questions? We're here to help! Fill out the form below or reach us at":
                "¿Tiene preguntas? ¡Estamos aquí para ayudarlo! Complete el formulario a continuación o contáctenos en",
            "About Swiftcom": "Acerca de Swiftcom",
            "Swiftcom is your trusted partner for seamless global communication. We specialize in providing innovative and cost-effective solutions tailored to meet the needs of travelers and home users alike. Whether you're staying connected with family and friends or managing international business, Swiftcom has got you covered.": "Swiftcom es su socio de confianza para una comunicación global sin interrupciones. Nos especializamos en proporcionar soluciones innovadoras y rentables adaptadas a las necesidades de los viajeros y usuarios domésticos. Ya sea que se mantenga conectado con familiares y amigos o gestione negocios internacionales, Swiftcom lo tiene cubierto.",
            "Our mission is to break down communication barriers by offering reliable, affordable, and globally accessible services. With a focus on flexibility, quality, and ease of use, Swiftcom ensures that you stay connected wherever you are in the world.": "Nuestra misión es eliminar las barreras de comunicación ofreciendo servicios confiables, asequibles y accesibles globalmente. Con un enfoque en la flexibilidad, la calidad y la facilidad de uso, Swiftcom asegura que se mantenga conectado dondequiera que esté en el mundo.",
            "Countries Covered": "Países Cubiertos",
            "Satisfied Customers": "Clientes Satisfechos",
            "Uptime Reliability": "Fiabilidad del Tiempo Activo",
            "Learn More About Our Services": "Obtenga más información sobre nuestros servicios",
            "Why Choose Swiftcom?": "¿Por qué elegir Swiftcom?",
            "Global Data eSIMs": "eSIMs de datos globales",
            "Stay connected worldwide with affordable data plans that work seamlessly across multiple networks in each country.": "Mantente conectado en todo el mundo con planes de datos asequibles que funcionan perfectamente en múltiples redes en cada país.",
            "Local Numbers (DIDs)": "Números locales (DIDs)",
            "Receive calls and SMS at local rates with dedicated numbers in over 60 countries.": "Recibe llamadas y SMS a tarifas locales con números dedicados en más de 60 países.",
            "Global Outgoing Calls & SMS": "Llamadas y SMS internacionales",
            "Make international calls and send SMS at competitive rates, avoiding high roaming charges.": "Realiza llamadas internacionales y envía SMS a tarifas competitivas, evitando los altos costos de roaming.",
            "Call Forwarding": "Reenvío de llamadas",
            "Forward calls from your native SIM to Swiftcom and never miss an important call while traveling.": "Reenvía llamadas desde tu SIM nativa a Swiftcom y nunca pierdas una llamada importante mientras viajas.",
            "Explore Our Plans": "Explora nuestros planes",
            "Calculate Your Costs": "Calcula tus costos",
            "Select a DID Country": "Selecciona un país para DID",
            "DID Cost": "Costo del DID",
            "Call Minutes": "Minutos de Llamada",
            "Call Destination Country": "País de Destino de la Llamada",
            "Call Cost": "Costo de Llamada",
            "SMS Count": "Cantidad de SMS",
            "SMS Destination Country": "País de Destino del SMS",
            "SMS Cost": "Costo del SMS",
            "Data in": "Datos en",
            "Cost per GB in": "Costo por GB en",
            "Subscription Fee": "Cuota de Suscripción",
            "Free (First Month)": "Gratis (Primer Mes)",
            "Calculate Total": "Calcular Total",
            "Total Cost": "Costo Total",
            "Loading...": "Cargando...",
            
            },
    },
    he: {
        translation: {
            "Welcome to Swiftcom": "ברוכים הבאים לסוויפטקום",
            "Seamless Global Communication Solutions": "פתרונות תקשורת גלובליים",
            "Home": "בית",
            "Download App": "הורד אפליקציה",
            "Prices": "מחירים",
            "Contact Us": "צור קשר",
            "Language": "שפה 🇮🇱",
            "Login": "התחברות",
            "Learn More": "למד עוד",
            "Submit": "שלח",
            "Have questions? We're here to help! Fill out the form below or reach us at":
                "יש לכם שאלות? אנחנו כאן כדי לעזור! מלאו את הטופס למטה או פנו אלינו במייל",
            "About Swiftcom": "על סוויפטקום",
            "Swiftcom is your trusted partner for seamless global communication. We specialize in providing innovative and cost-effective solutions tailored to meet the needs of travelers and home users alike. Whether you're staying connected with family and friends or managing international business, Swiftcom has got you covered.": "סוויפטקום היא השותפה המהימנה שלך לתקשורת גלובלית ללא הפרעות. אנו מתמחים במתן פתרונות חדשניים וחסכוניים המותאמים לצרכים של מטיילים ומשתמשים ביתיים כאחד. בין אם אתה נשאר בקשר עם משפחה וחברים או מנהל עסקים בינלאומיים, סוויפטקום מספקת לך את כל מה שאתה צריך.",
            "Our mission is to break down communication barriers by offering reliable, affordable, and globally accessible services. With a focus on flexibility, quality, and ease of use, Swiftcom ensures that you stay connected wherever you are in the world.": "המשימה שלנו היא לפרוץ את מחסומי התקשורת על ידי הצעת שירותים אמינים, נגישים וזמינים במחירים נוחים. עם דגש על גמישות, איכות ונוחות שימוש, סוויפטקום דואגת שתישאר מחובר לא משנה היכן אתה בעולם.",
            "Countries Covered": "מדינות כלולות",
            "Satisfied Customers": "לקוחות מרוצים",
            "Uptime Reliability": "אמינות זמן פעולה",
            "Learn More About Our Services": "למד עוד על השירותים שלנו",
            "Why Choose Swiftcom?": "למה - Swiftcom?",
            "Global Data eSIMs": "eSIMs גלובליים",
            "Stay connected worldwide with affordable data plans that work seamlessly across multiple networks in each country.": "שמור על קשר עולמי עם תוכניות Data משתלמות שעובדות בצורה חלקה ברשתות מרובות בכל מדינה.",
            "Local Numbers (DIDs)": "מספרים מקומיים (DIDs)",
            "Receive calls and SMS at local rates with dedicated numbers in over 60 countries.": "קבל שיחות ו-SMS במחירים מקומיים עם מספרים ייעודיים ביותר מ-60 מדינות.",
            "Global Outgoing Calls & SMS": "שיחות וסמס בינלאומיים",
            "Make international calls and send SMS at competitive rates, avoiding high roaming charges.": "בצע שיחות בינלאומיות ושלח SMS במחירים תחרותיים, תוך הימנעות מחיובי נדידה גבוהים.",
            "Call Forwarding": "העברת שיחות",
            "Forward calls from your native SIM to Swiftcom and never miss an important call while traveling.": "העבר שיחות מה-SIM המקומי שלך ל-Swiftcom ולעולם לא תפספס שיחה חשובה בזמן טיול.",
            "Explore Our Plans": "גלה את התוכניות שלנו",
            "Calculate Your Costs": "חשב את העלויות שלך",
            "Select a DID Country": "בחר מדינה עבור DID",
            "DID Cost": "עלות - DID",
            "Call Minutes": "מספר דקות שיחה",
            "Call Destination Country": "מדינת יעד השיחה",
            "Call Cost": "סך עלות השיחה",
            "SMS Count": "כמות הודעות סמס",
            "SMS Destination Country": "מדינת יעד הודעות סמס",
            "SMS Cost": "סך עלות הודעות סמס",
            "Data in": "נתונים ב",
            "Cost per GB in": "עלות לג'יגה ב-",
            "Subscription Fee": "דמי מנוי",
            "Free (First Month)": "חינם (חודש ראשון)",
            "Calculate Total": "חשב סה\"כ",
            "Total Cost": "עלות כוללת",
            "Loading...": "טוען..."
        
        },
    },
    de: {
        translation: {
            "Welcome to Swiftcom": "Willkommen bei Swiftcom",
            "Seamless Global Communication Solutions": "Nahtlose globale Kommunikationslösungen",
            "Home": "Startseite",
            "Download App": "App herunterladen",
            "Prices": "Preise",
            "Contact Us": "Kontaktiere uns",
            "Language": "Sprache 🇩🇪",
            "Login": "Anmelden",
            "Learn More": "Mehr erfahren",
            "Submit": "Absenden",
            "Have questions? We're here to help! Fill out the form below or reach us at":
                "Haben Sie Fragen? Wir sind hier, um zu helfen! Füllen Sie das untenstehende Formular aus oder erreichen Sie uns unter",
            "About Swiftcom": "Über Swiftcom",
            "Swiftcom is your trusted partner for seamless global communication. We specialize in providing innovative and cost-effective solutions tailored to meet the needs of travelers and home users alike. Whether you're staying connected with family and friends or managing international business, Swiftcom has got you covered.": "Swiftcom ist Ihr vertrauenswürdiger Partner für nahtlose globale Kommunikation. Wir sind spezialisiert auf innovative und kosteneffiziente Lösungen, die auf die Bedürfnisse von Reisenden und Heimnutzern zugeschnitten sind. Egal, ob Sie mit Familie und Freunden in Kontakt bleiben oder internationale Geschäfte abwickeln – Swiftcom hat alles, was Sie brauchen.",
            "Our mission is to break down communication barriers by offering reliable, affordable, and globally accessible services. With a focus on flexibility, quality, and ease of use, Swiftcom ensures that you stay connected wherever you are in the world.": "Unsere Mission ist es, Kommunikationsbarrieren abzubauen, indem wir zuverlässige, erschwingliche und weltweit zugängliche Dienste anbieten. Mit einem Fokus auf Flexibilität, Qualität und Benutzerfreundlichkeit sorgt Swiftcom dafür, dass Sie überall auf der Welt verbunden bleiben.",
            "Countries Covered": "Abgedeckte Länder",
            "Satisfied Customers": "Zufriedene Kunden",
            "Uptime Reliability": "Betriebszeit-Zuverlässigkeit",
            "Learn More About Our Services": "Erfahren Sie mehr über unsere Dienstleistungen",
            "Why Choose Swiftcom?": "Warum Swiftcom wählen?",
            "Global Data eSIMs": "Globale Daten-eSIMs",
            "Stay connected worldwide with affordable data plans that work seamlessly across multiple networks in each country.": "Bleiben Sie weltweit verbunden mit erschwinglichen Datentarifen, die nahtlos in mehreren Netzwerken in jedem Land funktionieren.",
            "Local Numbers (DIDs)": "Lokale Nummern (DIDs)",
            "Receive calls and SMS at local rates with dedicated numbers in over 60 countries.": "Empfangen Sie Anrufe und SMS zu lokalen Tarifen mit speziellen Nummern in über 60 Ländern.",
            "Global Outgoing Calls & SMS": "Globale ausgehende Anrufe & SMS",
            "Make international calls and send SMS at competitive rates, avoiding high roaming charges.": "Führen Sie internationale Anrufe und SMS zu wettbewerbsfähigen Preisen, ohne hohe Roaming-Gebühren.",
            "Call Forwarding": "Anrufweiterleitung",
            "Forward calls from your native SIM to Swiftcom and never miss an important call while traveling.": "Leiten Sie Anrufe von Ihrer nativen SIM zu Swiftcom weiter und verpassen Sie nie einen wichtigen Anruf auf Reisen.",
            "Explore Our Plans": "Entdecken Sie unsere Pläne",
            "Calculate Your Costs": "Berechnen Sie Ihre Kosten",
            "Select a DID Country": "Wählen Sie ein DID-Land",
            "DID Cost": "DID-Kosten",
            "Call Minutes": "Anrufminuten",
            "Call Destination Country": "Zielland des Anrufs",
            "Call Cost": "Anrufkosten",
            "SMS Count": "SMS-Anzahl",
            "SMS Destination Country": "Zielland der SMS",
            "SMS Cost": "SMS-Kosten",
            "Data in": "Daten in",
            "Cost per GB in": "Kosten pro GB in",
            "Subscription Fee": "Abonnementgebühr",
            "Free (First Month)": "Kostenlos (Erster Monat)",
            "Calculate Total": "Gesamtkosten berechnen",
            "Total Cost": "Gesamtkosten",
            "Loading...": "Wird geladen..."
            },
    },
};


i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n instance to react-i18next
    .init({
        resources,
        fallbackLng: "en", // Default language
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
    });

export default i18n;
