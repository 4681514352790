import React from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public"; // Icon for Global Data eSIMs
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"; // Icon for Local Numbers
import CallMadeIcon from "@mui/icons-material/CallMade"; // Icon for Outgoing Calls
import CallMergeIcon from "@mui/icons-material/CallMerge"; // Icon for Call Forwarding
import { useTranslation } from "react-i18next";

const Features = () => {
    const { t } = useTranslation(); // Access the translation function

    return (
        <Box
            id="features"
            sx={{
                position: "relative",
                padding: "50px 20px",
                backgroundColor: "var(--color-white)", // Use white as the background
                textAlign: "center", // Center all text
            }}
        >
            {/* Header */}
            <Typography
                variant="h4"
                component="h2"
                sx={{
                    fontWeight: "bold",
                    color: "var(--color-blue)", // Blue for the header
                    marginBottom: "30px",
                }}
            >
                {t("Why Choose Swiftcom?")}
            </Typography>

            {/* Features Grid */}
            <Grid container spacing={4} sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Box>
                        <PublicIcon sx={{ fontSize: 48, marginBottom: 2, color: "var(--color-blue)" }} />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "var(--color-black)",
                                marginBottom: "10px",
                            }}
                        >
                            {t("Global Data eSIMs")}
                        </Typography>
                        <Typography
                            sx={{
                                color: "var(--color-black)",
                                fontSize: "1rem",
                                lineHeight: 1.6,
                            }}
                        >
                            {t(
                                "Stay connected worldwide with affordable data plans that work seamlessly across multiple networks in each country."
                            )}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Box>
                        <PhoneAndroidIcon sx={{ fontSize: 48, marginBottom: 2, color: "var(--color-blue)" }} />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "var(--color-black)",
                                marginBottom: "10px",
                            }}
                        >
                            {t("Local Numbers (DIDs)")}
                        </Typography>
                        <Typography
                            sx={{
                                color: "var(--color-black)",
                                fontSize: "1rem",
                                lineHeight: 1.6,
                            }}
                        >
                            {t(
                                "Receive calls and SMS at local rates with dedicated numbers in over 60 countries."
                            )}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Box>
                        <CallMadeIcon sx={{ fontSize: 48, marginBottom: 2, color: "var(--color-blue)" }} />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "var(--color-black)",
                                marginBottom: "10px",
                            }}
                        >
                            {t("Global Outgoing Calls & SMS")}
                        </Typography>
                        <Typography
                            sx={{
                                color: "var(--color-black)",
                                fontSize: "1rem",
                                lineHeight: 1.6,
                            }}
                        >
                            {t(
                                "Make international calls and send SMS at competitive rates, avoiding high roaming charges."
                            )}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <Box>
                        <CallMergeIcon sx={{ fontSize: 48, marginBottom: 2, color: "var(--color-blue)" }} />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                                color: "var(--color-black)",
                                marginBottom: "10px",
                            }}
                        >
                            {t("Call Forwarding")}
                        </Typography>
                        <Typography
                            sx={{
                                color: "var(--color-black)",
                                fontSize: "1rem",
                                lineHeight: 1.6,
                            }}
                        >
                            {t(
                                "Forward calls from your native SIM to Swiftcom and never miss an important call while traveling."
                            )}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            {/* Call-to-Action Button */}
            <Box sx={{ marginTop: "40px" }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "var(--color-blue)",
                        color: "var(--color-white)",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        padding: "10px 20px",
                        fontSize: "1rem",
                        borderRadius: "5px",
                        ":hover": {
                            backgroundColor: "var(--color-black)",
                        },
                    }}
                >
                    {t("Explore Our Plans")}
                </Button>
            </Box>
        </Box>
    );
};

export default Features;
