import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Box,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../images/swiftcom logo.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const Header = () => {
    const { t } = useTranslation();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleScroll = (sectionId) => {
        if (location.pathname === "/") {
            document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
        } else {
            navigate("/");
            setTimeout(() => {
                document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
        setDrawerOpen(false);
    };

    const toggleDrawer = (open) => {
        setDrawerOpen(open);
    };

    const handleLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeLanguageMenu = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        document.body.dir = lng === "he" ? "rtl" : "ltr";
        closeLanguageMenu();
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "var(--color-blue)",
                color: "var(--text-color-light)",
                padding: "0 16px",
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                {/* Logo */}
                <IconButton
                    component={Link}
                    to="/"
                    sx={{ padding: 0 }}
                >
                    <Box
                        component="img"
                        src={Logo}
                        alt="Swiftcom Logo"
                        sx={{ height: { xs: "50px", sm: "60px" } }} // Larger logo size
                    />
                </IconButton>

                {/* Mobile Menu Button */}
                <IconButton
                    sx={{
                        color: "var(--text-color-light)",
                        display: { xs: "block", sm: "none" },
                    }}
                    onClick={() => toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>

                {/* Mobile Drawer */}
                <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => toggleDrawer(false)}
                >
                    <Box
                        className="header drawer"
                        sx={{
                            width: 250,
                            backgroundColor: "var(--color-blue)",
                            color: "var(--text-color-light)",
                            height: "100%",
                        }}
                    >
                        <List>
                            <ListItem button onClick={() => handleScroll("hero")} className="text-white">
                                <ListItemText primary={t("Home")} />
                            </ListItem>
                            <ListItem button onClick={() => handleScroll("AppDownload")} className="text-white">
                                <ListItemText primary={t("Download App")} />
                            </ListItem>
                            <ListItem button component={Link} to="/pricing" className="text-white">
                                <ListItemText primary={t("Prices")} />
                            </ListItem>
                            <ListItem button component={Link} to="/contact" className="text-white">
                                <ListItemText primary={t("Contact Us")} />
                            </ListItem>
                            <ListItem button onClick={handleLanguageMenu} className="text-white">
                                <Typography sx={{ fontSize: "1rem", display: "flex", alignItems: "center" }}>
                                    {t("Language")}
                                </Typography>
                            </ListItem>
                            <ListItem button component="a" href="https://swiftcom.app.swiftcom.cc/auth/login" target="_blank" className="text-white">
                                <ListItemText primary={t("Login")} />
                            </ListItem>

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={closeLanguageMenu}
                            >
                                <MenuItem onClick={() => changeLanguage("en")}>
                                    🇬🇧 {t("English")}
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage("es")}>
                                    🇪🇸 {t("Español")}
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage("he")}>
                                    🇮🇱 {t("עברית")}
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage("de")}>
                                    🇩🇪 {t("Deutsch")}
                                </MenuItem>
                            </Menu>
                        </List>
                    </Box>
                </Drawer>

                {/* Desktop Navigation */}
                <Box
                    sx={{
                        display: { xs: "none", sm: "flex" },
                        gap: "16px",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        component={Link}
                        to="/"
                        className="text-white"
                        sx={{
                            textDecoration: "none",
                            fontSize: "1rem", // Font size consistency
                            ":hover": { color: "var(--color-off-white)" },
                        }}
                    >
                        {t("Home")}
                    </Typography>
                    <Typography
                        component="button"
                        onClick={() => handleScroll("AppDownload")}
                        className="text-white"
                        sx={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            textDecoration: "none",
                            fontSize: "1rem",
                        }}
                    >
                        {t("Download App")}
                    </Typography>
                    <Typography
                        component={Link}
                        to="/pricing"
                        className="text-white"
                        sx={{
                            textDecoration: "none",
                            fontSize: "1rem",
                            ":hover": { color: "var(--color-off-white)" },
                        }}
                    >
                        {t("Prices")}
                    </Typography>
                    <Typography
                        component={Link}
                        to="/contact"
                        className="text-white"
                        sx={{
                            textDecoration: "none",
                            fontSize: "1rem",
                            ":hover": { color: "var(--color-off-white)" },
                        }}
                    >
                        {t("Contact Us")}
                    </Typography>
                    <Typography
                        onClick={handleLanguageMenu}
                        className="text-white"
                        sx={{
                            fontSize: "1rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            ":hover": { color: "var(--color-off-white)" },
                        }}
                    >
                        {t("Language")}
                    </Typography>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closeLanguageMenu}
                    >
                        <MenuItem onClick={() => changeLanguage("en")}>
                            🇬🇧 {t("English")}
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage("es")}>
                            🇪🇸 {t("Español")}
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage("he")}>
                            🇮🇱 {t("עברית")}
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage("de")}>
                            🇩🇪 {t("Deutsch")}
                        </MenuItem>
                    </Menu>
                    <Typography
                        component="a"
                        href="https://swiftcom.app.swiftcom.cc/auth/login"
                        target="_blank"
                        className="text-white"
                        sx={{
                            textDecoration: "none",
                            fontSize: "1rem",
                            ":hover": { color: "var(--color-off-white)" },
                        }}
                    >
                        {t("Login")}
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
