import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    Box,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const countries = ["USA", "UK", "Israel"]; // Replace with dynamic country list from an API

const Pricing = () => {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState("GBP");
    const [subscriptionFee] = useState(5.0); // Fixed subscription fee
    const [isFirstMonthFree] = useState(true); // Mark first month as free
    const [did, setDid] = useState({ country: "", cost: 0 });
    const [calls, setCalls] = useState({ minutes: 0, cost: 0 });
    const [sms, setSms] = useState({ count: 0, cost: 0 });
    const [data, setData] = useState([]);
    const [dataRates, setDataRates] = useState({});
    const [total, setTotal] = useState(0);

    // Simulated API data
    const fetchDidCost = (country) => {
        const costs = { USA: 2, UK: 3, Israel: 2.5 }; // Example DID costs
        return costs[country] || 0;
    };

    const fetchRates = () => {
        const callRates = { USA: 0.05, UK: 0.07, Israel: 0.06 }; // Per minute rates
        const smsRates = { USA: 0.01, UK: 0.015, Israel: 0.012 }; // Per SMS rates
        const dataCosts = { USA: 10, UK: 12, Israel: 15 }; // Per GB rates
        return { callRates, smsRates, dataCosts };
    };

    useEffect(() => {
        const rates = fetchRates();
        setDataRates(rates.dataCosts);
    }, []);

    const handleAddData = (country, gb) => {
        setData((prevData) => {
            const existing = prevData.find((d) => d.country === country);
            if (existing) {
                return prevData.map((d) => (d.country === country ? { ...d, gb } : d));
            }
            return [...prevData, { country, gb }];
        });
    };

    const calculateTotal = () => {
        const didCost = did.cost;
        const callCost = calls.minutes * (fetchRates().callRates[calls.country] || 0);
        const smsCost = sms.count * (fetchRates().smsRates[sms.country] || 0);
        const dataTotal = data.reduce(
            (sum, item) => sum + (dataRates[item.country] || 0) * item.gb,
            0
        );

        const totalCost =
            didCost + callCost + smsCost + dataTotal + (isFirstMonthFree ? 0 : subscriptionFee);

        setTotal(totalCost);
    };

    return (
        <Box id="pricing" sx={{ py: 8, bgcolor: "var(--color-off-white)", color: "var(--text-color-dark)" }}>
            <Container>
                <Typography variant="h4" component="h2" gutterBottom className="text-blue">
                    {t("Calculate Your Costs")}
                </Typography>
                <Box sx={{ mt: 4 }}>
                    {/* DID Selection */}
                    <TextField
                        select
                        label={t("Select a DID Country")}
                        value={did.country}
                        onChange={(e) => {
                            const country = e.target.value;
                            setDid({ country, cost: fetchDidCost(country) });
                        }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ className: "text-black" }}
                        InputProps={{ className: "text-black" }}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>{`${t(country)} DID`}</MenuItem>
                        ))}
                    </TextField>
                    <Typography className="text-black" sx={{ mt: 1 }}>
                        {t("DID Cost")}: {currency} {did.cost.toFixed(2)}
                    </Typography>

                    {/* Outgoing Calls */}
                    <TextField
                        label={t("Call Minutes")}
                        type="number"
                        fullWidth
                        margin="normal"
                        InputProps={{ className: "text-black" }}
                        onChange={(e) =>
                            setCalls({ ...calls, minutes: parseFloat(e.target.value || 0) })
                        }
                    />
                    <TextField
                        select
                        label={t("Call Destination Country")}
                        value={calls.country}
                        onChange={(e) =>
                            setCalls({ ...calls, country: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ className: "text-black" }}
                        InputProps={{ className: "text-black" }}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>{`${t(country)} Calls`}</MenuItem>
                        ))}
                    </TextField>
                    <Typography className="text-black" sx={{ mt: 1 }}>
                        {t("Call Cost")}: {currency} {(calls.minutes * (fetchRates().callRates[calls.country] || 0)).toFixed(2)}
                    </Typography>

                    {/* SMS */}
                    <TextField
                        label={t("SMS Count")}
                        type="number"
                        fullWidth
                        margin="normal"
                        InputProps={{ className: "text-black" }}
                        onChange={(e) =>
                            setSms({ ...sms, count: parseFloat(e.target.value || 0) })
                        }
                    />
                    <TextField
                        select
                        label={t("SMS Destination Country")}
                        value={sms.country}
                        onChange={(e) =>
                            setSms({ ...sms, country: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ className: "text-black" }}
                        InputProps={{ className: "text-black" }}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>{`${t(country)} SMS`}</MenuItem>
                        ))}
                    </TextField>
                    <Typography className="text-black" sx={{ mt: 1 }}>
                        {t("SMS Cost")}: {currency} {(sms.count * (fetchRates().smsRates[sms.country] || 0)).toFixed(2)}
                    </Typography>

                    {/* Data Service */}
                    {countries.map((country) => (
                        <Box key={country} sx={{ mt: 2 }}>
                            <TextField
                                label={`${t("Data in")} ${t(country)} (GB)`}
                                type="number"
                                fullWidth
                                InputProps={{ className: "text-black" }}
                                onChange={(e) =>
                                    handleAddData(country, parseFloat(e.target.value || 0))
                                }
                            />
                            <Typography className="text-black">
                                {t("Cost per GB in")} {t(country)}: {currency} {dataRates[country]?.toFixed(2) || t("Loading...")}
                            </Typography>
                        </Box>
                    ))}

                    {/* Subscription Fee */}
                    <Typography className="text-black" sx={{ mt: 2 }}>
                        {t("Subscription Fee")}: {isFirstMonthFree ? t("Free (First Month)") : `${currency} ${subscriptionFee.toFixed(2)}`}
                    </Typography>

                    {/* Calculate Total */}
                    <Button
                        variant="contained"
                        onClick={calculateTotal}
                        sx={{ mt: 3 }}
                        className="button"
                    >
                        {t("Calculate Total")}
                    </Button>
                    <Typography className="text-black" sx={{ mt: 2 }}>
                        {t("Total Cost")}: {currency} {total.toFixed(2)}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Pricing;
