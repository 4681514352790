import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const AppDownloadSection = () => {
    const { t } = useTranslation();

    return (
        <Box
            id="AppDownload"
            sx={{ py: 8, bgcolor: "grey.100", textAlign: "center" }}
        >
            <Container>
                <Typography variant="h4" gutterBottom>
                    {t("Get the Swiftcom App")}
                </Typography>
                <Typography sx={{ mb: 4, fontSize: "1.2rem" }}>
                    {t("Stay connected globally with our mobile app, available on both iOS and Android.")}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                    <a
                        href="https://apps.apple.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                            alt={t("Download on the App Store")}
                            style={{ height: "50px" }}
                        />
                    </a>
                    <a
                        href="https://play.google.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                            alt={t("Get it on Google Play")}
                            style={{ height: "50px" }}
                        />
                    </a>
                </Box>
            </Container>
        </Box>
    );
};

export default AppDownloadSection;
