import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";


const PricingPage = () => (
    <>
        <Header />
        <Pricing />
        <Footer />
    </>
);

export default PricingPage;
